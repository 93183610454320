<template>
  <b-card class="ofertes-list">
    <div
      class="oferta"
      v-for="oferta in ofertes"
      :key="oferta.borsa_oferta_id"
      :class="addClass(oferta)"
    >
      <div class="status">
        <b-icon-eye-slash-fill
          class="disabled"
          v-if="oferta.borsa_oferta_actiu === 0"
        ></b-icon-eye-slash-fill>
        <b-icon-lock-fill
          class="closed"
          v-if="
            oferta.borsa_oferta_tancada_flg && oferta.borsa_oferta_actiu === 1
          "
        ></b-icon-lock-fill>
      </div>
      <OfertaListItem :oferta="oferta"></OfertaListItem>
    </div>
  </b-card>
</template>

<script>
import OfertaListItem from "@/components/OfertaListItem";
export default {
  name: "OfertaList",
  components: {
    OfertaListItem,
  },
  props: {
    ofertes: {
      required: true,
      type: Array,
    },
  },
  methods: {
    addClass(oferta) {
      let classe = "";
      classe = oferta.borsa_oferta_actiu === 0 ? "noActive " : "";
      classe += oferta.borsa_oferta_tancada_flg === 1 ? "tancada" : "";
      return classe;
    },
  },
};
</script>

<style lang="scss" scoped>
.ofertes-list {
  .card-body {
    padding: 0;
  }
  //border:1px solid gray;
  margin-bottom: 20px;
  .oferta {
    padding: 10px;
    border-bottom: 15px solid #dddddd;
    position: relative;
    &:last-child {
      border: none;
    }
    &.tancada {
      //left:40px;
      background-color: rgba($red-eram, 0.07);
      // color:white
    }
    &.noActive {
      background-color: $fons;
      div {
        opacity: 0.5;
      }
    }
    .status {
      position: absolute;
      top: 10px;
      right: 15px;
      svg {
        width: 20px;
        height: 20px;
        &.disabled {
          color: $red-eram;
        }
        &.closed {
          color: $red-eram;
        }
        &.open {
          color: $red-eram;
        }
      }
    }
  }
}
</style>
