<template>
  <div class="oferta">
    <OfertaForm :oferta="oferta" :type="tipus" />
  </div>
</template>

<script>
import OfertaForm from "@/components/OfertaForm";

export default {
  name: "OfertaEdit",
  components: {
    OfertaForm,
  },
  data() {
    return {
      tipus: 2,
    };
  },

  beforeCreate() {
    const temp = this.$store.state.loading ? 500 : 0;
    setTimeout(() => {
      let breadcrumb = [
        {
          text: "Ofertes de Treball",
          to: { name: "OfertaEmpresa" },
        },
        {
          text: "Editar Oferta",
        },
      ];
      this.$store.commit("setBreadcrumb", breadcrumb);
    }, temp);
  },
  computed: {
    oferta() {
      return this.$store.state.ofertes.find(
        (o) => o.borsa_oferta_id === this.$route.params.id
      );
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
