import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//axios.defaults.baseURL = "https://api.eram.cat";
console.log("VUE_APP_API_URL", process.env.VUE_APP_API_URL);
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import VueSplide from "@splidejs/vue-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
Vue.use(VueSplide);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import Multiselect from "vue-multiselect";
// eslint-disable-next-line vue/multi-word-component-names
Vue.component("multiselect", Multiselect);

import VueLodash from "vue-lodash";
import lodash from "lodash";
Vue.use(VueLodash, { name: "custom", lodash: lodash });

import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
