<template>
  <div class="overlay d-flex justify-content-center align-items-center">
    <b-card class="inscripcio-item">
      <div class="close">
        <a href @click.prevent="surt()">
          <b-icon-x-circle />
        </a>
      </div>
      <splide
        :options="options"
        :slides="inscr"
        ref="secondary"
        @splide:mounted="onMounted"
      >
        <splide-slide
          v-for="ins in inscr"
          :key="ins.borsa_inscripcio_id"
          @mostraInscripcio="mostra()"
        >
          <b-row class="d-flex">
            <b-col cols="4">
              <InscripcioPhoto
                :inscr="ins"
                :index="index"
                @getIndex="mostra($event)"
              />
            </b-col>
            <b-col
              class="content d-flex flex-column justify-content-between pl-3"
            >
              <div>
                <h3>Carta de Presentació</h3>
                <div
                  class="comentari p-1"
                  v-html="ins.borsa_inscripcio_presentacio"
                ></div>
              </div>
              <div class="opcions">
                <div class="curriculum">
                  <a
                    :href="
                      'https://images.eram.cat/curriculum/' +
                      ins.persona_curriculum_doc
                    "
                    target="_blank"
                  >
                    <b-icon-download></b-icon-download>
                    <span>Descarrega Currículum Vitae</span>
                  </a>
                </div>
                <div
                  class="d-flex justify-content-around"
                  v-if="!hasConveni(ins)"
                >
                  <b-button
                    :class="
                      ins.borsa_inscripcio_estat_fk === 10 ? 'active' : ''
                    "
                    @click="changeEstat(ins.borsa_inscripcio_id, 10)"
                  >
                    <b-icon-circle></b-icon-circle>
                    <span>Pendent</span>
                  </b-button>
                  <b-button
                    :class="
                      ins.borsa_inscripcio_estat_fk === 11 ? 'active' : ''
                    "
                    @click="changeEstat(ins.borsa_inscripcio_id, 11)"
                  >
                    <b-icon-arrow-up-circle-fill></b-icon-arrow-up-circle-fill>
                    <span>Pre-seleccionar</span>
                  </b-button>
                  <b-button
                    :class="
                      ins.borsa_inscripcio_estat_fk === 12 ? 'active' : ''
                    "
                    @click="changeEstat(ins.borsa_inscripcio_id, 12)"
                  >
                    <b-icon-arrow-down-circle-fill></b-icon-arrow-down-circle-fill>
                    <span>Descartar</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </splide-slide>
      </splide>
    </b-card>
  </div>
</template>

<script>
import InscripcioPhoto from "@/components/InscripcioPhoto";
import axios from "axios";

export default {
  name: "InscripcioItem",
  components: {
    InscripcioPhoto,
  },
  props: {
    inscripcions: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inscr: this.inscripcions,
      options: {
        slideFocus: false,
        width: "100%",
        pagination: false,
        arrows: true,
        focus: 0,
        start: 0,
      },
    };
  },
  computed: {},
  created() {
    console.log("index a mostrar", this.index);
    this.options.start = this.index;
  },
  methods: {
    hasConveni(i) {
      let offer = this.$store.getters.ofertes.find(
        (o) => o.borsa_oferta_id == i.borsa_inscripcio_oferta_fk
      );
      console.log("test", offer, offer?.borsa_oferta_conveni_flg);
      return offer?.borsa_oferta_conveni_flg;
    },
    onMounted(splide) {
      console.log("splide mounted");
      console.log(splide);
      // splide.go(this.index);
    },
    changeEstat(id, status) {
      //console.log(id,status);
      axios
        .put("empresa/inscripcio/" + id, { borsa_inscripcio_estat_fk: status })
        .then(() => {
          let index = this.inscr.indexOf(
            this.inscr.find((i) => i.borsa_inscripcio_id === id)
          );
          this.inscr[index].borsa_inscripcio_estat_fk = status;
        });
    },
    mostra() {
      this.$emit("mostraInscripcio", this.inscr);
    },
    surt() {
      this.$emit("noDetail", 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, 0.5);
  z-index: 9999;
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9999;
    color: $red-eram;
  }
  .inscripcio-item {
    margin: 0 15%;
  }

  .comentari {
    overflow: hidden;
    left: 0;
    bottom: 0;
  }
  .curriculum {
    margin: 0 10px 20px;
  }
  .curriculum span {
    margin-left: 10px;
  }
  .opcions {
    button {
      background-color: rgba($red-eram, 0.3);
      margin: 0 10px;
      color: white;
      span {
        padding-left: 10px;
      }
      &.active {
        background-color: rgba($red-eram, 1);
      }
    }
  }
}
</style>
