import { render, staticRenderFns } from "./InscripcioPhoto.vue?vue&type=template&id=6a85833a&scoped=true&"
import script from "./InscripcioPhoto.vue?vue&type=script&lang=js&"
export * from "./InscripcioPhoto.vue?vue&type=script&lang=js&"
import style0 from "./InscripcioPhoto.vue?vue&type=style&index=0&id=6a85833a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a85833a",
  null
  
)

export default component.exports