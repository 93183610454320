<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  watch: {
    $route(to) {
      document.title =
        to.meta.title || "Borsa de Treball -  Escola Universitària ERAM";
    },
  },
  beforeCreate() {
    document.title = "Borsa de Treball -  Escola Universitària ERAM";
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@import "node_modules/sweetalert2/src/sweetalert2";
.text-muted {
  color: #eee !important;
}
.swal2-container {
  top: 65px !important;
  .swal2-popup {
    &.swal2-toast {
      padding: 10px 20px;
      background-color: white !important;

      border-radius: 0;
      .swal2-title,
      .swal2-content {
        color: $gray-600;
      }
    }
    .swal2-icon {
      border: none !important;
    }
    .swal2-timer-progress-bar {
      background: $red-eram;
    }
  }
}
body {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $fons;
  // padding-bottom:30px;
}
// Titol
.title {
  margin-bottom: 20px;
}
.afegir {
  color: $red-eram;
  .icon {
    margin-right: 10px;
  }
}
a {
  color: $red-eram;
  &:hover {
    color: $red-eram;
  }
}

//Formularis
.btn,
button {
  border-radius: 0;
  padding: 10px 0;
}
.form-control[readonly] {
  background-color: rgba(white, 0.3);
}
.custom-select,
.b-form-datepicker {
  display: block;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid white;
  //background: transparent;
  color: $red-eram;
  position: relative;
  //&::after{
  //  content:"a";
  //  position:absolute;
  //  top:0;
  //  right:0;
  //  width: 50px;
  //  height:50px;
  //  background-color:blue;
  //  z-index:9999;
  //}
}
.b-form-datepicker {
  padding-left: 10px !important;
  label,
  button,
  button:hover {
    color: $red-eram;
  }
  &.focus {
    color: $red-eram;
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: $red-eram;
  background-color: $red-eram;
}

//General
.bloc,
.card {
  border-radius: 0;
  border: none;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

input.form-control {
  //padding-left:40px;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid white;
  border-radius: 0;
  color: white !important;
}
input.form-control::placeholder {
  color: rgba(white, 0.5);
}
input.form-control:focus {
  box-shadow: none;
  border-bottom-width: 2px;
  background-color: transparent;
  border-color: white;
}
button.btn-secondary,
.register a {
  margin-top: 20px;
  padding: 10px 0;
  border-radius: 0;
  background-color: rgba(white, 0.4);
  color: white;
  width: 100%;
  border: none;
  //transition: 0.2s transform;

  &:hover {
    box-shadow: 0 0 0 1px rgba(white, 0.8);
    background-color: rgba(white, 0.4);
    //transform: scaleY(1.05);
  }
  &.cancel {
    background-color: rgba(white, 1);
    color: $red-eram;
  }
}
//Overlay
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, 0.5);
  z-index: 9999;
  .message {
    background-color: white;
    padding: 20px;
  }
}
//multiselect

.multiselect__select:before {
  border-color: $red-eram transparent transparent;
  color: $red-eram;
}
.multiselect__tags {
  border-radius: 0;
}
.multiselect__tag {
  background-color: rgba($red-eram, 0.8);
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: $red-eram;
}

.multiselect__option--highlight {
  background: rgba($red-eram, 0.8);
  outline: none;
  color: #fff;
}
.multiselect__option--highlight::after {
  background-color: $red-eram;
  height: 100%;
  display: flex;
  align-items: center;
}
.message {
  font-size: 24px;
  color: #aaa;
  font-style: italic;
  p {
    padding: 50px 0 30px;
  }
}
</style>
