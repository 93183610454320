<template>
  <div class="oferta-item" v-if="!loading">
    <div
      class="d-md-flex justify-content-between align-items-center title"
      v-if="oferta"
    >
      <h2 class="">{{ oferta.borsa_oferta_lloc }}</h2>
      <b-button
        class="assignar"
        @click="mostraAssignar()"
        v-if="oferta.borsa_oferta_actiu && !oferta.borsa_oferta_tancada_flg"
      >
        Assignar oferta
      </b-button>
    </div>
    <div class="d-flex justify-content-between align-items-end mb-3">
      <div class="badges">
        <b-badge class="pendent">
          <b-icon-circle></b-icon-circle>{{ numPendents }} Pendents
        </b-badge>
        <b-badge class="seleccionat">
          <b-icon-arrow-up-circle-fill></b-icon-arrow-up-circle-fill
          >{{ numPreseleccionats }} Pre-seleccionats
        </b-badge>
        <b-badge class="descartat">
          <b-icon-arrow-down-circle-fill></b-icon-arrow-down-circle-fill
          >{{ numDescartats }} Descartats
        </b-badge>
      </div>
      <div class="actions">
        <b-badge>
          <b-icon-people></b-icon-people
          ><span>{{ numAssignats }}/{{ oferta.borsa_oferta_places }}</span>
        </b-badge>
      </div>
    </div>
    <div class="slide">
      <splide
        :options="options"
        :slides="inscripcions"
        ref="primary"
        v-if="inscripcions.length > numItems"
      >
        <splide-slide v-for="(inscr, index) in inscripcions" :key="index">
          <div
            class="inscripcio position-relative"
            @click="mostra(index)"
            :class="
              inscr.borsa_inscripcio_estat_fk === 12
                ? 'descartat'
                : inscr.borsa_inscripcio_estat_fk === 11
                ? 'seleccionat'
                : 'pendent'
            "
          >
            <div class="plus d-flex justify-content-center align-items-center">
              <b-icon-plus class="icon"></b-icon-plus>
            </div>
            <div class="status">
              <b-icon-circle
                v-if="inscr.borsa_inscripcio_estat_fk === 10"
              ></b-icon-circle>
              <b-icon-arrow-up-circle-fill
                v-else-if="
                  inscr.borsa_inscripcio_estat_fk === 11 ||
                  inscr.borsa_inscripcio_estat_fk == 20
                "
              ></b-icon-arrow-up-circle-fill>
              <b-icon-arrow-down-circle-fill
                v-else
              ></b-icon-arrow-down-circle-fill>
            </div>
            <InscripcioPhoto
              :inscr="inscr"
              :index="index"
              @getIndex="mostra($event)"
            />
          </div>
        </splide-slide>
      </splide>
      <div class="users d-flex" v-else>
        <div
          v-for="(i, index) in inscripcions"
          :key="i.empresa_inscripcio_id"
          class="item"
        >
          <div
            class="inscripcio position-relative"
            @click="mostra(index)"
            :class="
              i.borsa_inscripcio_estat_fk === 12
                ? 'descartat'
                : i.borsa_inscripcio_estat_fk === 11
                ? 'seleccionat'
                : 'pendent'
            "
          >
            <div class="plus d-flex justify-content-center align-items-center">
              <b-icon-plus class="icon"></b-icon-plus>
            </div>
            <div class="status">
              <b-icon-circle
                v-if="i.borsa_inscripcio_estat_fk === 10"
              ></b-icon-circle>
              <b-icon-arrow-up-circle-fill
                v-else-if="i.borsa_inscripcio_estat_fk === 11"
              ></b-icon-arrow-up-circle-fill>
              <b-icon-arrow-down-circle-fill
                v-else
              ></b-icon-arrow-down-circle-fill>
            </div>
            <InscripcioPhoto
              :inscr="i"
              :index="index"
              @getIndex="mostra($event)"
            />
          </div>
        </div>
        <div
          v-for="index in numItems - inscripcions.length"
          :key="index"
          class="item"
        >
          <div class="inscripcio empty">
            <b-icon-person-square></b-icon-person-square>
          </div>
        </div>
      </div>
    </div>
    <b-card class="oferta">
      <b-row class="justify-content-center">
        <b-col md="4" class="info">
          <div class="informacio">
            <p class="dept" v-if="oferta.borsa_oferta_empresa_departament_fk">
              <b-icon-building></b-icon-building>
              <span>{{ departament.empresa_departament_nom }}</span>
            </p>
            <p class="d-flex">
              <b-icon-tag></b-icon-tag>
              <span>
                <span v-for="(cat, index) in categories" :key="index">{{
                  cat.categoria_nom_ca
                }}</span>
              </span>
            </p>
            <p class="d-flex">
              <b-icon-newspaper></b-icon-newspaper>
              <span>
                <span v-for="(rel, index) in relacions" :key="index"
                  >{{ rel.borsa_relacio_nom }}
                </span>
              </span>
            </p>
            <p class="retribucio" v-if="!oferta.borsa_oferta_retribucio_flg">
              <b-icon-cash-stack></b-icon-cash-stack> <span>No</span>
            </p>
            <p class="retribucio" v-else>
              <b-icon-cash-stack></b-icon-cash-stack>
              <span>Retribuït {{ oferta.borsa_oferta_retribucio_text }}</span>
            </p>
            <p class="horari">
              <b-icon-clock></b-icon-clock>
              <span>{{
                horari
                  ? "Horari " + horari.borsa_horari_nom
                  : "Horari Indefinit"
              }}</span>
            </p>
            <p class="experiencia">
              <b-icon-person-check></b-icon-person-check>
              <span>
                {{
                  experiencia
                    ? experiencia.borsa_experiencia_nom + " d'experiència"
                    : "No cal experiència"
                }}</span
              >
            </p>
            <p class="durada">
              <b-icon-calendar3></b-icon-calendar3>
              <span>{{
                durada
                  ? durada.borsa_durada_nom + " de durada"
                  : "Durada Indefinida"
              }}</span>
            </p>
          </div>
          <div class="buttons">
            <b-button
              @click="editarOferta()"
              v-if="!oferta.borsa_oferta_tancada_flg"
              >Editar</b-button
            >
            <b-button
              @click="mostraDesactivar()"
              v-if="
                oferta.borsa_oferta_actiu && !oferta.borsa_oferta_tancada_flg
              "
              >Desactivar</b-button
            >
            <b-button
              @click="activarOferta()"
              v-if="!oferta.borsa_oferta_actiu && esVerificada"
              >Activar</b-button
            >
            <p class="py-2" v-if="!oferta.borsa_oferta_actiu && !esVerificada">
              No Activa. Pendent de Validació
            </p>
            <b-button
              @click="duplicarOferta()"
              v-if="oferta.borsa_oferta_tancada_flg"
              >Duplicar</b-button
            >
          </div>
        </b-col>
        <b-col md="8">
          <div class="description">
            <h3>Descripció</h3>
            <p v-html="oferta.borsa_oferta_descripcio"></p>
            <h3>Requisits</h3>
            <p
              v-if="oferta.borsa_oferta_requisits"
              v-html="oferta.borsa_oferta_requisits"
            ></p>
            <p v-else>No és necessari cap requisit.</p>
            <h3>Coneixements</h3>
            <p
              v-if="oferta.borsa_oferta_coneixements"
              v-html="oferta.borsa_oferta_coneixements"
            ></p>
            <p v-else>No es necessiten coneixements previs.</p>
            <h3>Observacions</h3>
            <p
              v-if="
                oferta.borsa_oferta_observacions &&
                oferta.borsa_oferta_observacions !== ''
              "
              v-html="oferta.borsa_oferta_observacions"
            ></p>
            <p v-else>No hi ha Observacions</p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <InscripcioItem
      :inscripcions="inscripcions"
      :index="index"
      v-if="detail"
      @noDetail="detail = false"
    />
    <div
      class="overlay d-flex justify-content-center align-items-center"
      v-if="desactiva"
    >
      <b-card class="modal-desactivar">
        <h2 class="title">Quin és el motiu per desactivar aquesta Oferta?</h2>
        <b-form-select v-model="motiuDesactivar" :options="optionsDesactivar">
        </b-form-select>
        <div class="d-flex justify-content-around">
          <b-button variant="info" @click="onDesactivarOferta()"
            >Desactivar</b-button
          >
          <b-button variant="danger" @click="onCancel()">Cancelar</b-button>
        </div>
        <div>
          <b-button
            class="btn-delete"
            variant="danger"
            @click="onEsborrarOferta()"
            size="sm"
            >Esborrar Permenentment la Oferta</b-button
          >
        </div>
      </b-card>
    </div>
    <div
      class="overlay d-flex justify-content-center align-items-center"
      v-if="assigna"
    >
      <b-card class="modal-assignar" v-if="optionsAssignar.length > 0">
        <h2 class="title">Assignar Oferta</h2>
        <p>
          Selecciona l'alumne en pràctiques que vulguis. Recorda que l'alumne ha
          d'estar pre-seleccionat.
        </p>
        <p>
          <strong
            >Un cop assignat l'alumne a la oferta, no podràs canviar
            l'assignació.</strong
          >
        </p>
        <label>Alumne</label>
        <b-form-select
          v-model="usuariAssignat"
          :options="optionsAssignar"
          @change="optionsRelacioAssignar()"
        >
        </b-form-select>
        <label>Relació Contractual</label>
        <b-form-select
          v-model="relacioUsuariAssignat"
          :options="relacioAssignar"
        ></b-form-select>
        <div class="d-flex justify-content-between buttons">
          <b-button
            variant="info"
            @click="onAssignarOferta()"
            :disabled="!usuariAssignat || !relacioUsuariAssignat"
            >Assignar</b-button
          >
          <b-button variant="danger" @click="onCancel()">Cancelar</b-button>
        </div>
      </b-card>
      <b-card class="modal-assignar" v-else>
        <h2 class="title">No has pre-seleccionat cap alumne</h2>
        <div class="buttons">
          <b-button variant="danger" @click="onCancel()">Cancelar</b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import InscripcioItem from "@/components/InscripcioItem.vue";
import InscripcioPhoto from "@/components/InscripcioPhoto.vue";
import axios from "axios";

export default {
  name: "OfertaSingle",
  components: {
    InscripcioItem,
    InscripcioPhoto,
  },
  data() {
    return {
      relacioAssignar: [],
      optionsDesactivar: [
        {
          value: null,
          text: "Escull una opció",
          disabled: true,
        },
        "Vull modificar l'oferta",
        "La vull desactivar temporalment",
        "Ja he trobat algú que no és del centre.",
        "Ja no necessito aquest perfil",
        "Ningú s'apunta a l'oferta",
      ],
      motiuDesactivar: "",
      assigna: false,
      desactiva: false,
      inscripcio: {},
      options: {
        //autoWidth:true,
        type: "loop",
        perPage: 4,
        perMove: 1,
        gap: "1rem",
      },
      detail: false,
      index: 0,
      usuariAssignat: null,
      relacioUsuariAssignat: null,
    };
  },
  computed: {
    numItems() {
      let screenWidth = window.innerWidth;
      console.log(screenWidth);
      if (screenWidth < 768) {
        return 2; //function to transform your src to large
      } else {
        return 4;
      }
    },
    loading() {
      return this.$store.state.loading;
    },

    optionsAssignar() {
      return this.inscripcions
        .filter((i) => i.borsa_inscripcio_estat_fk === 11)
        .map(function (item) {
          return {
            value: item.borsa_inscripcio_id,
            text: item.persona_nom + " " + item.persona_cog1,
          };
        });
    },
    numAssignats() {
      return this.inscripcions.filter(
        (i) => i.borsa_inscripcio_seleccionat_flg === 1
      ).length;
    },
    numPendents() {
      return this.inscripcions.filter((i) => i.borsa_inscripcio_estat_fk === 10)
        .length;
    },
    numPreseleccionats() {
      return this.inscripcions.filter((i) => i.borsa_inscripcio_estat_fk === 11)
        .length;
    },
    numDescartats() {
      return this.inscripcions.filter((i) => i.borsa_inscripcio_estat_fk === 12)
        .length;
    },
    esVerificada() {
      return this.$store.getters.empresa.empresa_verificada_flg;
    },
    oferta() {
      return this.$store.getters.ofertes.find(
        (o) => o.borsa_oferta_id == this.$route.params.id
      );
    },
    ofertes() {
      return this.$store.getters.ofertes;
    },
    departament() {
      return this.$store.state.departaments.find(
        (d) =>
          d.empresa_departament_id ===
          this.oferta.borsa_oferta_empresa_departament_fk
      );
    },
    categoria() {
      return this.$store.state.categoriaOferta.find(
        (c) => c.categoria_id === this.oferta.borsa_oferta_categoria_fk
      );
    },
    categories() {
      let cats = JSON.parse(this.oferta.borsa_oferta_categoria_fk);
      return this.$store.state.categoria.filter((c) =>
        cats.includes(c.categoria_id)
      );
    },
    experiencia() {
      return this.$store.state.experienciaOferta.find(
        (c) =>
          c.borsa_experiencia_id === this.oferta.borsa_oferta_experiencia_fk
      );
    },
    horari() {
      return this.$store.state.horariOferta.find(
        (c) => c.borsa_horari_id === this.oferta.borsa_oferta_horari_fk
      );
    },
    durada() {
      return this.$store.state.duradaOferta.find(
        (c) => c.borsa_durada_id === this.oferta.borsa_oferta_durada_fk
      );
    },
    relacions() {
      let relacions = JSON.parse(this.oferta.borsa_oferta_relacio_fk);
      if (relacions.length > 0) {
        return this.$store.state.relacioOferta.filter((c) =>
          relacions.includes(c.borsa_relacio_id)
        );
      } else {
        return this.$store.state.relacioOferta.filter(
          (c) => c.borsa_relacio_id === relacions
        );
      }
    },

    inscripcions() {
      return this.$store.state.inscripcions.filter(
        (i) => i.borsa_inscripcio_oferta_fk == this.$route.params.id
      );
    },
  },
  beforeCreate() {
    const temp = this.$store.state.loading ? 500 : 0;
    setTimeout(() => {
      let breadcrumb = [
        {
          text: "Ofertes de treball",
          to: { name: "OfertaEmpresa" },
        },
        {
          text: this.oferta.borsa_oferta_lloc,
        },
      ];
      this.$store.commit("setBreadcrumb", breadcrumb);
    }, temp);
  },
  methods: {
    optionsRelacioAssignar() {
      console.log(this.usuariAssignat);
      let insc = this.inscripcions.find(
        (i) => i.borsa_inscripcio_id === this.usuariAssignat
      );
      let rels = JSON.parse(insc.borsa_inscripcio_relacio_fk);
      this.relacioAssignar = this.$store.getters.relacioOferta
        .filter((i) => rels.includes(i.borsa_relacio_id))
        .map(function (item) {
          return {
            value: item.borsa_relacio_id,
            text: item.borsa_relacio_nom,
          };
        });
    },
    mostra(e) {
      console.log("mostra", e);
      this.detail = true;
      this.index = e;
      // this.inscripcio = i;
    },
    mostraDesactivar() {
      this.desactiva = true;
    },
    mostraAssignar() {
      this.assigna = true;
    },
    onDesactivarOferta() {
      this.oferta.borsa_oferta_actiu = 0;
      this.oferta.borsa_oferta_motiu_baixa = this.motiuDesactivar;
      const oferta = {
        borsa_oferta_empresa_fk: this.oferta.borsa_oferta_empresa_fk,
        borsa_oferta_actiu: 0,
        borsa_oferta_motiu_baixa: this.motiuDesactivar,
      };
      axios
        .put("/empresa/borsa/" + this.oferta.borsa_oferta_id, oferta)
        .then(() => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: "Has Desactivat la oferta. Ja no és visible pels alumnes",
            icon: "success",
            iconHtml:
              '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
              '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
              "</svg>",
            timer: 5000,
            showConfirmButton: false,
          });
          this.$router.push({ name: "OfertaEmpresa" });
        });
    },
    onEsborrarOferta() {
      axios.delete("/empresa/borsa/" + this.oferta.borsa_oferta_id).then(() => {
        let index = this.ofertes.findIndex(
          (o) => o.borsa_oferta_id === this.oferta.borsa_oferta_id
        );
        this.ofertes.splice(index, 1);
        this.$swal.fire({
          toast: true,
          position: "top-end",
          text: "Has Eliminat la oferta.",
          icon: "success",
          iconHtml:
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
            '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
            "</svg>",
          timer: 5000,
          showConfirmButton: false,
        });
        this.$router.push({ name: "OfertaEmpresa" });
      });
    },
    activarOferta() {
      this.oferta.borsa_oferta_actiu = 1;
      const oferta = {
        borsa_oferta_empresa_fk: this.oferta.borsa_oferta_empresa_fk,
        borsa_oferta_actiu: 1,
      };
      axios
        .put("/empresa/borsa/" + this.oferta.borsa_oferta_id, oferta)
        .then(() => {
          //this.$router.push({name:"Oferta"})
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: "Has Activat la oferta. Ara ja és visible pels estudiants",
            icon: "success",
            iconHtml:
              '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
              '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
              "</svg>",
            timer: 5000,
            showConfirmButton: false,
          });
        });
    },
    duplicarOferta() {
      let offer = this.oferta;
      let anyActiu = this.$store.getters.anys.find((a) => a.any_actiu == 1);
      offer.borsa_oferta_actiu = 0;
      offer.borsa_oferta_data_incorporacio = null;
      offer.borsa_oferta_any_fk = anyActiu.any_id;
      offer.borsa_oferta_conveni_flg = 0;
      offer.borsa_oferta_tancada_flg = 0;
      delete offer.borsa_oferta_id;
      delete offer.borsa_oferta_data;
      axios.post("/empresa/borsa", offer).then(() => {
        this.$router.push({ name: "OfertaEmpresa" });
        this.$swal.fire({
          toast: true,
          position: "top-end",
          text: "Has duplicat la oferta.",
          icon: "success",
          iconHtml:
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
            '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
            "</svg>",
          timer: 5000,
          showConfirmButton: false,
        });
      });
    },
    async onAssignarOferta() {
      this.oferta.borsa_oferta_tancada_flg = 1;
      this.oferta.borsa_oferta_relacio_fk = this.relacioUsuariAssignat;

      await axios.put(
        "/empresa/borsa/" + this.oferta.borsa_oferta_id,
        this.oferta
      );

      this.inscripcions.forEach(async (i) => {
        await axios.put("/empresa/inscripcio/" + i.borsa_inscripcio_id, {
          borsa_inscripcio_estat_fk:
            i.borsa_inscripcio_id === this.usuariAssignat ? 20 : 21,
        });
      });
      this.$store.dispatch("fetchInscripcions");
      let inscripcio = this.inscripcions.find(
        (i) => i.borsa_inscripcio_id === this.usuariAssignat
      );

      if (this.oferta.borsa_oferta_relacio_fk === 1) {
        let conveni = {
          conveni_inscripcio_fk: inscripcio.borsa_inscripcio_id,
          conveni_nocurricular:
            inscripcio.borsa_inscripcio_practiques_flg === 1 &&
            this.oferta.borsa_oferta_actiu_practica_flg === 1
              ? 0
              : 1,
          conveni_any_fk: this.oferta.borsa_oferta_any_fk,
          conveni_empresa_fk: this.$store.state.empresa.empresa_id,
          conveni_persona_fk: inscripcio.borsa_inscripcio_persona_fk,
          conveni_borsa_oferta_fk: inscripcio.borsa_inscripcio_oferta_fk,
        };
        console.log("Conveni abans de crear-lo", conveni);
        let conv = await axios.post("/empresa/conveni", conveni);
        if (conv) {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            text: "Has assignat la oferta i s'ha generat el conveni.",
            icon: "success",
            iconHtml:
              '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
              '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
              "</svg>",
            timer: 5000,
            showConfirmButton: false,
          });
          this.$store.dispatch("fetchInscripcions");
          this.$store.dispatch("fetchConvenis");
          this.$router.push({ name: "ConveniEmpresa" });
        }
      } else {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          text: "Has assignat la oferta.",
          icon: "success",
          iconHtml:
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
            '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
            "</svg>",
          timer: 5000,
          showConfirmButton: false,
        });
        this.$router.push({ name: "OfertaEmpresa" });
      }
    },
    onCancel() {
      this.desactiva = false;
      this.assigna = false;
    },
    editarOferta() {
      this.$router.push({
        name: "OfertaEdit",
        params: { id: this.oferta.borsa_oferta_id },
      });
    },
    assignarOferta() {},
  },
};
</script>

<style lang="scss">
.title {
  margin-bottom: 10px;
}
.slide {
  margin-bottom: 20px;
  .status {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 900;
    color: white;
  }

  .users .item {
    width: 50%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 1200px) {
    .users .item {
      width: 25%;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .inscripcio {
    position: relative;
    border: 5px solid white;
    &.descartat {
      border: 5px solid black;
      svg {
        color: black;
      }
    }
    &.seleccionat {
      border: 5px solid $red-eram;
      svg {
        color: $red-eram;
      }
    }
    &.empty {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 220px;

      svg {
        width: 70px;
        height: 70px;
        color: white;
      }
    }

    .plus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($fons, 0.6);
      z-index: 9999;
      mix-blend-mode: screen;
      opacity: 0;
      transition: 0.3s all;
      .icon {
        font-size: 150px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
.oferta {
  padding: 20px;
  .info {
    color: $red-eram;
    border-right: 1px solid $fons;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .informacio {
      svg {
        margin-top: 5px;
      }
      p > span {
        padding-left: 10px;
        span {
          display: block;
        }
      }
    }
    .buttons {
      button {
        background-color: rgba($red-eram, 0.7);
        &:hover {
          background-color: $red-eram;
        }
      }
    }
  }
  .description {
    max-width: 800px;
    margin: 0 auto;
    h3 {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 30px;
    }
    h3:first-child {
      margin-top: 0;
    }
  }
}
.badge {
  margin: 0 10px 0 0;
  &.descartat {
    background-color: black;
  }
  &.seleccionat {
    background-color: $red-eram;
  }
  &.pendent {
    background-color: white;
    color: black;
  }
  svg {
    margin-right: 10px;
  }
}
button.assignar {
  margin-top: 0;
  width: auto;
  padding: 5px 10px;
  background-color: $red-eram;
  &:hover {
    color: black;
  }
}

.actions .badge {
  background-color: $red-eram;
  margin: 0;
}

.overlay .modal-desactivar {
  background-color: $red-eram;
  color: white;
  padding: 20px;
  .title,
  select {
    margin-bottom: 30px;
  }
  button {
    margin: 0;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
    &.btn-delete {
      width: auto;
      padding: 0 5px;
      margin: 20px 0 0;
      &:hover {
        background-color: black;
      }
    }
  }
}
.modal-assignar {
  max-width: 500px;
  padding: 30px;
  select {
    background-color: $red-eram;
    color: white;
    margin-bottom: 20px;
  }
  button {
    display: block;
    margin-top: 0;
    //width: auto;
    padding: 5px 15px;
    //background-color: $red-eram;
    &:hover {
      color: black;
    }
  }
}
</style>
