<template>
  <div class="position-relative photo">
    <b-img
      fluid
      :src="
        'https://images.eram.cat/slir/w400-c3x4/persona/' +
        inscr.persona_foto_foto
      "
    ></b-img>
    <h3>{{ inscr.persona_nom }}<br />{{ inscr.persona_cog1 }}</h3>
  </div>
</template>

<script>
export default {
  name: "InscripcioPhoto",

  props: {
    inscr: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.photo h3 {
  position: absolute;
  right: 10px;
  bottom: 0;
  text-align: right;
  color: white;
  text-shadow: 0 0 10px rgba(black, 0.5);
  font-weight: bold;
  font-size: 40px;
}
</style>
