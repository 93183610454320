import { render, staticRenderFns } from "./InscripcioItem.vue?vue&type=template&id=bdb891d8&scoped=true&"
import script from "./InscripcioItem.vue?vue&type=script&lang=js&"
export * from "./InscripcioItem.vue?vue&type=script&lang=js&"
import style0 from "./InscripcioItem.vue?vue&type=style&index=0&id=bdb891d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdb891d8",
  null
  
)

export default component.exports