import Vue from "vue";
import VueRouter from "vue-router";
import Oferta from "@/views/Backend/Oferta.vue";
import OfertaSingle from "@/views/Backend/OfertaSingle.vue";
import OfertaCreate from "@/views/Backend/OfertaCreate.vue";
import OfertaEdit from "@/views/Backend/OfertaEdit.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginEmpresa",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "Borsa de Treball -  Escola Universitària ERAM",
      metaTags: [
        {
          name: "description",
          content: "The home page of our example app.",
        },
        {
          property: "og:description",
          content: "The home page of our example app.",
        },
      ],
    },
  },
  {
    path: "/lost",
    name: "LostPassword",
    component: () => import("@/views/Lost.vue"),
  },
  {
    path: "/register",
    name: "RegisterEmpresa",
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/recover",
    name: "RecoveEmpresa",
    component: () => import("@/views/Recover.vue"),
  },
  {
    path: "/activate",
    name: "ActivateEmpresa",
    component: () => import("@/views/Activate.vue"),
  },
  {
    path: "/backend",
    name: "BackendEmpresa",
    redirect: "/backend/perfil",
    component: () => import("@/views/Backend.vue"),
    meta: {
      title: "Borsa de Treball -  Escola Universitària ERAM",
    },
    children: [
      {
        path: "dashboard",
        name: "DashboardEmpresa",
        component: () => import("@/views/Backend/Dashboard.vue"),
      },
      {
        path: "faqs",
        name: "FaqsEmpresa",
        component: () => import("@/views/Backend/Faqs.vue"),
      },

      {
        path: "ofertes",
        name: "OfertaEmpresa",
        component: Oferta,
      },
      {
        path: "ofertes/create",
        name: "OfertaCreate",
        component: OfertaCreate,
      },
      {
        path: "ofertes/:id",
        name: "OfertaSingle",
        component: OfertaSingle,
      },
      {
        path: "ofertes/:id/edit",
        name: "OfertaEdit",
        component: OfertaEdit,
      },
      {
        path: "departaments",
        name: "DepartamentEmpresa",
        component: () => import("@/views/Backend/Departament.vue"),
      },
      {
        path: "convenis",
        name: "ConveniEmpresa",
        component: () => import("@/views/Backend/Conveni.vue"),
      },
      {
        path: "perfil",
        name: "PerfilEmpresa",
        component: () => import("@/views/Backend/Perfil.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

export default router;
