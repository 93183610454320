<template>
  <div class="oferta-item">
    <div
      class="tipus"
      v-for="tipus in tipusOferta"
      :key="tipus.borsa_relacio_id"
    >
      <span>{{ tipus.borsa_relacio_abbr }}</span>
    </div>
    <div class="date">{{ oferta.borsa_oferta_data | formatDate }}</div>
    <div class="titol">
      <a href @click.prevent="mostraOferta()">{{ oferta.borsa_oferta_lloc }}</a>
    </div>
    <div class="inscrits">
      <b-badge pill variant="danger" size="sm" @click="mostraInscripcions()"
        >{{ numInscrits }}
        {{ numInscrits === 1 ? "inscrit" : "inscrits" }}</b-badge
      >
    </div>
    <!--    <b-avatar-group size="40px" v-if="numInscrits>1">-->
    <!--      <b-avatar-->
    <!--        v-for="p in inscripcions"-->
    <!--        :key="p.borsa_inscripcio_id"-->
    <!--        :src="p.persona_foto_foto?'https://images.eram.cat/img-persona-40-40/' + p.persona_foto_foto:null"-->
    <!--        variant="primary"-->
    <!--      ></b-avatar>-->
    <!--    </b-avatar-group>-->
    <!--    <b-avatar-->
    <!--      v-for="p in inscripcions"-->
    <!--      :key="p.borsa_inscripcio_id"-->
    <!--      v-else-->
    <!--      :src="p.persona_foto_foto?'https://images.eram.cat/img-persona-40-40/' + p.persona_foto_foto:null"-->
    <!--      variant="primary"-->
    <!--    ></b-avatar>-->

    <div class="estat">{{ oferta.borsa_oferta_actiu_flg }}</div>
  </div>
</template>

<script>
import moment from "moment";
//import axios from "axios";
export default {
  name: "OfertaListItem",
  props: {
    oferta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  filters: {
    formatDate: function (date) {
      return moment(date).locale("ca_ES").format("DD-MM-YYYY");
    },
  },
  computed: {
    tipusOferta() {
      let rel = JSON.parse(this.oferta.borsa_oferta_relacio_fk);
      if (rel.length > 0) {
        return this.$store.getters.relacioOferta.filter((r) =>
          rel.includes(r.borsa_relacio_id)
        );
      } else {
        return this.$store.getters.relacioOferta.filter(
          (r) => r.borsa_relacio_id === this.oferta.borsa_oferta_relacio_fk
        );
      }
    },
    inscripcions() {
      return this.$store.state.inscripcions.filter(
        (i) => i.borsa_inscripcio_oferta_fk === this.oferta.borsa_oferta_id
      );
    },
    numInscrits() {
      return this.inscripcions.length;
    },
  },
  methods: {
    mostraOferta() {
      console.log(this.oferta.borsa_oferta_lloc);
      this.$router.push({
        name: "OfertaSingle",
        params: { id: this.oferta.borsa_oferta_id },
      });
    },
    mostraInscripcions() {
      console.log(this.inscripcions);
      this.makeToast("danger");
    },
    makeToast(variant) {
      this.$bvToast.toast("This is toast number", {
        // title: 'BootstrapVue Toast',
        autoHideDelay: 5000,
        variant: variant,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.oferta-item {
  display: flex;
  justify-content: flex-start;

  div {
    display: flex;
    align-items: center;
    margin: 0 10px;
    text-align: left;
    &.inscrits {
      justify-self: flex-end;
    }
  }
  .tipus {
    //width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($red-eram, 0.1);
    margin: 0;
    color: $red-eram;
    font-size: 14px;
    margin-right: 5px;
    padding: 5px;

    span {
      font-weight: 900;
    }
  }
}
</style>
