<template>
  <div class="ofertes" v-if="!loading">
    <div class="d-flex justify-content-between align-items-center">
      <h2 class="title">Ofertes de Treball</h2>
      <router-link :to="{ name: 'OfertaCreate' }" class="afegir">
        <b-icon-plus-circle class="icon"></b-icon-plus-circle
        ><span class="d-none d-md-inline"> Crea una Oferta</span>
      </router-link>
    </div>
    <div class="filters d-md-flex justify-content-between">
      <h4>Filtra per</h4>
      <div>
        <b-badge
          :class="filtreRelacio !== 0 ? 'active' : ''"
          @click="filterRelacio()"
          >{{ filtreRelacioText }}</b-badge
        >
        <b-badge
          :class="filtreEstat !== 0 ? 'active' : ''"
          @click="filterfiltreEstat()"
          >{{ filtreEstatText }}</b-badge
        >
      </div>
    </div>
    <OfertaList :ofertes="orderedOfertes" v-if="orderedOfertes.length > 0" />
    <b-col cols="12" class="message" v-if="!orderedOfertes.length > 0">
      <p>
        No tens ofertes creades.<br />
        En aquesta secció podràs crear i gestionar les teves ofertes de treball.
      </p>
    </b-col>
  </div>
</template>

<script>
import OfertaList from "@/components/OfertaList";

export default {
  name: "OfertaEmpresa",
  components: {
    OfertaList,
  },
  data() {
    return {
      filtreRelacio: 0,
      filtreEstat: 0,
      filtreRelacioText: "Tots els tipus de contracte",
      filtreEstatText: "Totes les ofertes",
    };
  },
  beforeCreate() {
    const temp = this.$store.state.loading ? 500 : 0;
    setTimeout(() => {
      let breadcrumb = [
        {
          text: "Ofertes de Treball",
        },
      ];
      this.$store.commit("setBreadcrumb", breadcrumb);
    }, temp);
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    orderedOfertes() {
      return this._.orderBy(this.ofertes, "borsa_oferta_data", "desc");
    },
    ofertes() {
      if (this.filtreEstat === 0 && this.filtreRelacio === 0) {
        return this.$store.getters.ofertes;
      } else if (this.filtreEstat === 0) {
        return this.$store.getters.ofertes.filter((o) =>
          o.borsa_oferta_relacio_fk.includes(this.filtreRelacio)
        );
      } else if (this.filtreRelacio === 0) {
        let o = [];
        switch (this.filtreEstat) {
          case 1:
            o = this.$store.getters.ofertes.filter(
              (o) => o.borsa_oferta_tancada_flg === 1
            );
            break;
          case 2:
            o = this.$store.getters.ofertes.filter(
              (o) => o.borsa_oferta_actiu === 0
            );
            break;
          case 3:
            o = this.$store.getters.ofertes.filter(
              (o) =>
                o.borsa_oferta_actiu === 1 && o.borsa_oferta_tancada_flg === 0
            );
            break;
          /* case 4:
            o = this.$store.getters.ofertes.filter(
              o =>
                o.borsa_oferta_actiu === 0 &&
                o.borsa_oferta_inscripcio_flg === 0
            );
            break;*/
        }
        return o;
        //return this.$store.getters.ofertes.filter(o => o.borsa_oferta_filtreEstat_flg===this.filtreEstat);
      } else {
        let o;
        switch (this.filtreEstat) {
          case 1:
            o = this.$store.getters.ofertes.filter(
              (o) =>
                o.borsa_oferta_relacio_fk.includes(this.filtreRelacio) &&
                o.borsa_oferta_tancada_flg === 1
            );
            break;
          case 2:
            o = this.$store.getters.ofertes.filter(
              (o) =>
                o.borsa_oferta_relacio_fk.includes(this.filtreRelacio) &&
                o.borsa_oferta_actiu === 0 &&
                o.borsa_oferta_inscripcio_flg === 1
            );
            break;
          case 3:
            o = this.$store.getters.ofertes.filter(
              (o) =>
                o.borsa_oferta_relacio_fk.includes(this.filtreRelacio) &&
                o.borsa_oferta_actiu === 1 &&
                o.borsa_oferta_tancada_flg === 0
            );
            break;
          case 4:
            o = this.$store.getters.ofertes.filter(
              (o) =>
                o.borsa_oferta_relacio_fk.includes(this.filtreRelacio) &&
                o.borsa_oferta_actiu === 0 &&
                o.borsa_oferta_inscripcio_flg === 0
            );
            break;
        }
        return o;
        //return this.$store.getters.ofertes.filter(o => (o.borsa_oferta_filtreRelacio_fk === this.filtreRelacio && o.borsa_oferta_filtreEstat_flg===this.filtreEstat));
      }
    },
  },
  methods: {
    filterRelacio() {
      if (this.filtreRelacio < 3) {
        this.filtreRelacio++;
      } else {
        this.filtreRelacio = 0;
      }
      switch (this.filtreRelacio) {
        case 0:
          this.filtreRelacioText = "Tots els tipus de contracte";
          break;
        case 1:
          this.filtreRelacioText = "Pràctiques";
          break;
        case 2:
          this.filtreRelacioText = "Contracte laboral";
          break;
        case 3:
          this.filtreRelacioText = "Freelance";
          break;
      }
    },
    filterfiltreEstat() {
      if (this.filtreEstat < 4) {
        this.filtreEstat++;
      } else {
        this.filtreEstat = 0;
      }
      switch (this.filtreEstat) {
        case 0:
          this.filtreEstatText = "Totes les ofertes";
          break;
        case 1:
          this.filtreEstatText = "Ofertes Tancades";
          break;
        case 2:
          this.filtreEstatText = "Ofertes No actives";
          break;
        case 3:
          this.filtreEstatText = "Ofertes Actives";
          break;
        case 4:
          this.filtreEstatText = "Ofertes Pendents";
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filters {
  background-color: $red-eram;
  margin: 10px 0;
  padding: 20px;
  color: white;
  .badge {
    padding: 10px 20px;
    color: white;
    background-color: $red-eram;
    border: 1px solid white;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    &:hover {
      color: $red-eram;
      background-color: rgba(white, 0.7);
    }
    &.active {
      color: $red-eram;
      background-color: white;
    }
  }
}
</style>
