<template>
  <div>
    <h2 class="title">{{ title }}</h2>
    <b-form @submit.prevent="onSubmit()">
      <b-row class="justify-content-center formulari">
        <b-col cols="12">
          <b-card class="form p-md-5">
            <b-form-group label-cols-sm="4">
              <template slot="label">
                Tipologia d'oferta*<b-icon-info-circle-fill
                  class="tipusHelp"
                  v-b-modal.modalRelacio
                  @click="tipologiaHelp()"
                ></b-icon-info-circle-fill>
              </template>
              <multiselect
                v-model="relacioModel"
                :options="relacio"
                trackBy="value"
                label="text"
                :multiple="true"
                :searchable="false"
                :allowEmpty="false"
                :close-on-select="false"
                :show-labels="true"
                placeholder="Escull la Tipologia de la oferta"
              >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">{{ props.option.text }}</span
                    ><span class="option__small">{{ props.option.desc }}</span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group label-cols-sm="4" label="Lloc de treball*">
              <b-form-input
                required
                type="text"
                v-model="offer.borsa_oferta_lloc"
                placeholder="Descripció breu del lloc ofertat"
              ></b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="4" label="Departament de l'Empresa*">
              <b-form-select
                v-model="offer.borsa_oferta_empresa_departament_fk"
                :options="departaments"
              ></b-form-select>
            </b-form-group>
            <b-form-group label-cols-sm="4" label="Categoria/es de l'oferta*">
              <multiselect
                v-model="categoriesModel"
                :options="categories"
                trackBy="value"
                label="text"
                :multiple="true"
                :searchable="false"
                :allowEmpty="false"
                :close-on-select="false"
                :show-labels="true"
                placeholder="Escull Categories"
              >
              </multiselect>
            </b-form-group>
            <b-form-group label="Descripció*">
              <vue-editor
                :editor-toolbar="toolbar"
                required
                id="editor"
                v-model="offer.borsa_oferta_descripcio"
              ></vue-editor>
            </b-form-group>
            <!--            <b-form-group label-cols-sm="4" label="Experiència">-->
            <!--              <b-form-select-->
            <!--                v-model="offer.borsa_oferta_experiencia_fk"-->
            <!--                :options="experiencia"-->
            <!--              ></b-form-select>-->
            <!--            </b-form-group>-->
            <b-form-group label="Requisits">
              <vue-editor
                :editor-toolbar="toolbar"
                id="editor2"
                v-model="offer.borsa_oferta_requisits"
              ></vue-editor>
            </b-form-group>
            <b-form-group label="Coneixements">
              <vue-editor
                :editor-toolbar="toolbar"
                id="editor3"
                v-model="offer.borsa_oferta_coneixements"
              ></vue-editor>
            </b-form-group>
            <b-form-group label-cols-sm="4" label="Horari">
              <b-form-select
                v-model="offer.borsa_oferta_horari_fk"
                :options="horari"
              ></b-form-select>
            </b-form-group>
            <!--            <b-form-group label-cols-sm="4" label="Durada">-->
            <!--              <b-form-select-->
            <!--                v-model="offer.borsa_oferta_durada_fk"-->
            <!--                :options="durada"-->
            <!--              ></b-form-select>-->
            <!--            </b-form-group>-->
            <b-form-group label-cols-sm="4" label="Retribució">
              <b-row>
                <b-col>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="offer.borsa_oferta_retribucio_flg"
                    name="retribucio"
                    value="1"
                    unchecked-value="0"
                  >
                    És retribuïda
                  </b-form-checkbox>
                </b-col>
                <b-col v-if="offer.borsa_oferta_retribucio_flg === 1">
                  <b-form-input
                    type="text"
                    v-model="offer.borsa_oferta_retribucio_text"
                    placeholder="Retribució"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols-sm="4"
              label="Assignada a un Alumne/a (opcional)"
            >
              <b-row>
                <!--                <b-col cols="3" >-->
                <!--                  <b-form-checkbox-->
                <!--                      id="checkbox-2"-->
                <!--                      v-model="isAssignada"-->
                <!--                      name="assignada"-->
                <!--                      :value="true"-->
                <!--                      :unchecked-value="false"-->
                <!--                  >-->
                <!--                    {{-->
                <!--                      isAssignada-->
                <!--                          ? "Sí"-->
                <!--                          : "No"-->
                <!--                    }}-->
                <!--                  </b-form-checkbox>-->
                <!--                </b-col>-->
                <b-col>
                  <b-form-input
                    type="text"
                    v-model="dniAlumne"
                    placeholder="NIF Alumne"
                    description="Amb lletra i sense espais"
                  ></b-form-input>
                  <span style="font-size: 12px">{{ nomAlumne }}</span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Altres Observacions">
              <vue-editor
                :editor-toolbar="toolbar"
                required
                id="editor4"
                v-model="offer.borsa_oferta_observacions"
              ></vue-editor>
            </b-form-group>
            <div class="text-right">
              <b-button type="submit" class="px-5">
                {{ textBoto }}
              </b-button>
            </div>
            <p class="missatge" v-if="missatge">{{ missatge }}</p>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
    <b-modal
      id="modalRelacio"
      title="Tipologies d'Ofertes"
      :hide-footer="true"
      header-class="header"
    >
      <h3>Pràctiques Curriculars</h3>
      <p>
        Donec ullamcorper nulla non metus auctor fringilla. Aenean lacinia
        bibendum nulla sed consectetur. Sed posuere consectetur est at lobortis.
        Donec id elit non mi porta gravida at eget metus. Cras justo odio,
        dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros.
      </p>
      <h3>Pràctiques NO Curriculars</h3>
      <p>
        Donec ullamcorper nulla non metus auctor fringilla. Aenean lacinia
        bibendum nulla sed consectetur. Sed posuere consectetur est at lobortis.
        Donec id elit non mi porta gravida at eget metus. Cras justo odio,
        dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros.
      </p>
      <h3>Relació Laboral</h3>
      <p>
        Donec ullamcorper nulla non metus auctor fringilla. Aenean lacinia
        bibendum nulla sed consectetur. Sed posuere consectetur est at lobortis.
        Donec id elit non mi porta gravida at eget metus. Cras justo odio,
        dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros.
      </p>
      <h3>Freelance</h3>
      <p>
        Donec ullamcorper nulla non metus auctor fringilla. Aenean lacinia
        bibendum nulla sed consectetur. Sed posuere consectetur est at lobortis.
        Donec id elit non mi porta gravida at eget metus. Cras justo odio,
        dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum
        nulla sed consectetur. Morbi leo risus, porta ac consectetur ac,
        vestibulum at eros.
      </p>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
// import { required } from 'vuelidate/lib/validators'

export default {
  name: "OfertaForm",
  components: {
    Multiselect,
  },
  props: {
    oferta: {
      type: Object,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      offer: this.oferta,
      assignada: false,
      missatge: "Els camps amb * son obligatoris",
      nomAlumne: "",
      // isAssignada: false
      // validations:{
      //   relacioModel:{required},
      //   categoriesModel:{required}
      // }
    };
  },
  mounted() {
    console.log(this.offer);
  },
  watch: {},
  computed: {
    dniAlumne: {
      get() {
        let student = this.$store.getters.students.find(
          (student) =>
            student.id === this.offer.borsa_oferta_assignada_persona_fk
        );
        if (student) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.nomAlumne = student.nom;
          return student.nif;
        } else {
          return "";
        }
      },
      set(value) {
        let student = this.$store.getters.students.find(
          (student) => student.nif === value.toUpperCase()
        );
        if (student) {
          this.offer.borsa_oferta_assignada_persona_fk = student.id;
          this.nomAlumne = student.nom;
        } else {
          //this.isAssignada=true;
          this.offer.borsa_oferta_assignada_persona_fk = null;
          this.nomAlumne = "Aquest DNI no coincideix amb cap alumne.";
        }
      },
    },
    categoriesModel: {
      get() {
        if (this.offer.borsa_oferta_categoria_fk === null) {
          return [];
        } else {
          let cats = JSON.parse(this.offer.borsa_oferta_categoria_fk);
          if (cats.length > 0) {
            return this.categories.filter((cat) => cats.includes(cat.value));
          } else {
            return [];
          }
        }
      },
      set(val) {
        // console.log('set',val)
        let cats = val.map((r) => {
          return r.value;
        });
        this.offer.borsa_oferta_categoria_fk = JSON.stringify(cats);
        console.log(this.offer.borsa_oferta_categoria_fk);
      },
    },
    relacioModel: {
      get() {
        if (this.offer.borsa_oferta_relacio_fk == null) {
          //console.log("no relacio");
          return [];
        } else {
          let rels = JSON.parse(this.offer.borsa_oferta_relacio_fk);
          //console.log("rels", rels);

          if (rels.length > 0) {
            //console.log("get", rels);
            return this.relacio.filter((rel) => rels.includes(rel.value));
          } else {
            return [];
          }
        }
      },
      set(val) {
        //console.log("set", val);
        let rels = val.map((r) => {
          return r.value;
        });
        this.offer.borsa_oferta_relacio_fk = JSON.stringify(rels);
        console.log("set", this.offer.borsa_oferta_relacio_fk);
      },
    },
    departaments() {
      let dep = this.$store.state.departaments
        .filter((d) => d.empresa_departament_actiu_flg === 1)
        .map(function (item) {
          return {
            value: item.empresa_departament_id,
            text: item.empresa_departament_nom,
          };
        });
      dep.unshift({
        value: null,
        text: "No tinc departament",
        //disabled: true
      });

      return dep;
    },
    categories() {
      return this.$store.state.categoria.map(function (item) {
        return {
          value: item.categoria_id,
          text: item.categoria_nom_ca,
        };
      });
    },
    relacio() {
      return this.$store.state.relacioOferta.map(function (item) {
        return {
          value: item.borsa_relacio_id,
          desc: item.borsa_relacio_descripcio,
          text: item.borsa_relacio_nom,
        };
      });
    },
    // durada() {
    //   let durada = this.$store.state.duradaOferta.map(function(item) {
    //     return {
    //       value: item.borsa_durada_id,
    //       text: item.borsa_durada_nom
    //     };
    //   });
    //   durada.unshift({
    //     value: null,
    //     text: "Escull una durada",
    //     disabled: true
    //   });
    //   return durada;
    // },
    horari() {
      let horari = this.$store.state.horariOferta.map(function (item) {
        return {
          value: item.borsa_horari_id,
          text: item.borsa_horari_nom,
        };
      });
      horari.unshift({
        value: null,
        text: "Escull un horari",
        disabled: true,
      });
      return horari;
    },
    // experiencia() {
    //   let experiencia = this.$store.state.experienciaOferta.map(function(item) {
    //     return {
    //       value: item.borsa_experiencia_id,
    //       text: item.borsa_experiencia_nom
    //     };
    //   });
    //   experiencia.unshift({
    //     value: null,
    //     text: "Escull la experiència laboral",
    //     disabled: true
    //   });
    //   return experiencia;
    // },
    toolbar() {
      return this.$store.state.customToolbar;
    },
    title() {
      return this.type === 1 ? "Crea una Oferta" : "Edita la Oferta";
    },
    textBoto() {
      return this.type === 1 ? "Crea una Oferta" : "Edita Oferta";
    },
  },
  methods: {
    onSubmit() {
      console.log(this.offer);
      if (
        this.offer.borsa_oferta_categoria_fk === "[]" ||
        this.offer.borsa_oferta_categoria_fk === null
      ) {
        this.$swal.fire({
          title: "Error",
          text: "Has de posar com a mínim una categoria a l'oferta.",
          icon: "error",
          confirmButtonText: "Entesos",
        });

        //  this.missatge ="Has de posar com a mínim una categoria a l'oferta."
      } else if (
        this.offer.borsa_oferta_relacio_fk === "[]" ||
        this.offer.borsa_oferta_relacio_fk === null
      ) {
        this.$swal.fire({
          title: "Error",
          text: "Has de posar la tipologia de l'oferta.",
          icon: "error",
          confirmButtonText: "Entesos",
        });
        // this.missatge = "Has de posar la relació contractual de l'oferta.";
      } else {
        console.log("envia");

        if (this.type === 1) {
          let anyActiu = this.$store.state.anys.find((a) => a.any_actiu === 1);

          this.offer.borsa_oferta_any_fk = anyActiu.any_id;
          axios.post("/empresa/borsa", this.offer).then((res) => {
            console.log(res);
            this.$swal.fire({
              toast: true,
              position: "top-end",
              text: "Has creat la Oferta correctament",
              icon: "success",
              iconHtml:
                '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
                '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
                "</svg>",
              timer: 5000,
              showConfirmButton: false,
            });

            //ens falta saber si són curriculars o no...
            /*  if (this.offer.borsa_oferta_assignada_persona_fk){
            	let inscripcio = {
            		borsa_inscripcio_oferta_fk: res.data.borsa_oferta_id,
	               borsa_inscripcio_persona_fk: res.data.borsa_oferta_assignada_persona_fk,
	               borsa_inscripcio_relacio_fk: res.data.borsa_oferta_relacio_fk,
	               borsa_inscripcio_presentacio: "[AUTOMÀTIC] - Adjudicat per l'empresa",
	               borsa_inscripcio_valig_flg:1,
	               borsa_inscripcio_practiques_flg: 1 //NO HO SABEM
               }
	            axios.post("/empresa/borsa", inscripcio).then(res => {
	            	console.log(res)
	            })
            }*/

            this.$router.push({ name: "OfertaEmpresa" });
            this.$store.dispatch("fetchOfertes");
          });
        } else if (this.type === 2) {
          axios
            .put("/empresa/borsa/" + this.offer.borsa_oferta_id, this.offer)
            .then((res) => {
              this.$store.dispatch("fetchOfertes");
              console.log(res);
              this.$swal.fire({
                toast: true,
                position: "top-end",
                text: "Has editat la Oferta correctament",
                icon: "success",
                iconHtml:
                  '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16">\n' +
                  '  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>\n' +
                  "</svg>",
                timer: 5000,
                showConfirmButton: false,
              });

              this.$router.go(-1);
            });
        }
      }
    },
    tipologiaHelp() {
      console.log("Help");
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  margin-top: 10px;
  background-color: $red-eram;
  color: white;
}
.option__title {
}
.option__small {
  display: block;
  font-size: 12px;
  margin-top: 5px;
}
.tipusHelp {
  margin-left: 10px;
  color: white;
  transition: all 0.5s;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:hover {
    color: black;
  }
}
#modalRelacio {
  h3 {
    color: $red-eram;
    font-size: 20px;
  }
}
:host ::ng-deep .header {
  background-color: $red-eram;
  color: white;
  .close {
    color: white;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}
.missatge {
  font-size: 14px;
}
</style>
